var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{ref:"profile_form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_RESELLERS))?_c('base-input',{attrs:{"label":_vm.$t('COMMON.RESELLER'),"placeholder":_vm.$t('COMMON.RESELLER')}},[_c('reseller-selector',{attrs:{"reseller":_vm.role.reseller.id,"filterable":true,"showAll":false,"allowNone":true},on:{"resellerChanged":function (resellerId) {
          _vm.role.reseller.id = resellerId;
          _vm.role.organization.id = null;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.reseller}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS))?_c('base-input',{attrs:{"label":_vm.$t('COMMON.ORGANIZATION'),"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"organization":_vm.role.organization.id,"filterable":true,"showAll":false,"allowNone":true,"filterReseller":_vm.role.reseller.id},on:{"organizationChanged":function (organizationId) {
          _vm.role.organization.id = organizationId;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}}),(
      _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS) &&
      _vm.role.organization.id
    )?_c('base-input',{attrs:{"label":("" + (_vm.$t('COMMON.LOCATION'))),"placeholder":_vm.$t('COMMON.LOCATION')}},[_c('locations-selector',{attrs:{"locations":_vm.role.allowedLocations,"filterable":true,"showAll":false,"multiple":true,"organization":_vm.role.organization.id},on:{"locationsChanged":function (locations) {
          _vm.role.allowedLocations = locations;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.location}}),_c('base-input',{attrs:{"label":((_vm.$t('COMMON.NAME')) + " (*)"),"placeholder":_vm.$t('COMMON.NAME')},on:{"change":_vm.onFormChanged},model:{value:(_vm.role.name),callback:function ($$v) {_vm.$set(_vm.role, "name", $$v)},expression:"role.name"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.name}}),_c('base-input',{attrs:{"label":_vm.$t('COMMON.EXCERPT'),"placeholder":_vm.$t('COMMON.EXCERPT')}},[_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.role.excerpt),callback:function ($$v) {_vm.$set(_vm.role, "excerpt", $$v)},expression:"role.excerpt"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}}),_c('div',{staticClass:"row mb-5"},[_c('span',{staticClass:"form-control-label col-12 mb-2"},[_vm._v(" "+_vm._s(((_vm.$t("COMMON.PERMISSIONS")) + " (*)"))+" ")]),_vm._l((_vm.models),function(model){return _c('div',{key:model,staticClass:"col-12 row"},[_c('span',{staticClass:"form-control-label col-12 mb-2 mt-3"},[_vm._v(" "+_vm._s(_vm.$t(("COMMON." + model)))+" ")]),_vm._l((_vm.permissionsList.filter(
          function (perm) { return perm.key.includes(model) && !!_vm.permissionsModel[perm.id]; }
        )),function(permission){return _c('div',{key:permission.key,staticClass:"col-6"},[_c('base-checkbox',{on:{"change":_vm.onFormChanged},model:{value:(_vm.permissionsModel[permission.id].checked),callback:function ($$v) {_vm.$set(_vm.permissionsModel[permission.id], "checked", $$v)},expression:"permissionsModel[permission.id].checked"}},[_vm._v(" "+_vm._s(_vm.$t(("PERMISSIONS." + (permission.key))))+" ")])],1)})],2)})],2),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.permissions}}),_c('div',{staticClass:"my-4"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.role.id ? _vm.$t("ROLES.EDIT_ROLE") : _vm.$t("ROLES.ADD_ROLE"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }