export default {
  type: "roles",
  name: null,
  excerpt: '',
  relationshipNames: ["permissions", "organization", "reseller", "allowedLocations"],
  permissions: [],
  organization: {
    type: "organizations",
    id: null,
  },
  reseller: {
    type: "resellers",
    id: null,
  },
  allowedLocations: [],
};
